import { Fragment } from "react";
import { useAppSelector } from "store";
import Title from "components/left-sidebar-1/title";
import Item from "components/left-sidebar-1/item";
import Logo from "components/left-sidebar-1/logo";

const LeftSidebar: React.FC = () => {
  const navigation = useAppSelector((state) => state.navigation);

  const roleAdmin = ["ADM_CAFE", "ADM_CANTEEN", "ADM_FOTOCOPY", "CASHIER"]
  const roleAssets = ["SA", "GA"]

  let permissions: string[] = []
  let role = { code: '' }
  if (typeof window !== 'undefined') {
    let ls_permission = localStorage?.getItem('permissions') as string;
    let ls_roles = localStorage?.getItem('roles') as string;
    if(ls_permission !== null)  {
      permissions = JSON.parse( ls_permission);
    }
    if(ls_roles !== null)  {
      let tmp_roles = JSON.parse(ls_roles)
      if(typeof tmp_roles === 'object') {
        if(tmp_roles.length > 0) {
          role = tmp_roles[0]
        }
       }
    }

  }

  return (
    <div className="h-full md:col-span-2 text-gray-900 bg-white border-r border-gray-100 left-sidebar left-sidebar-1 dark:bg-gray-900 dark:border-gray-800 dark:text-white pb-10">
      <Logo />
      <div className="max-h-[64rem] overflow-y-scroll">
        {navigation.map((menu, i) => {
          if(menu?.roleCodes) {
            if (!menu.roleCodes.includes(role?.code)) return null
          }
          if (menu.code && permissions != undefined) {
            // if (menu.title === 'Assets Management' && !roleAssets.includes(role?.code)) return null
            
            // if (!roleAdmin.includes(role?.code) && ['Assets Management', 'Report', 'Cash Bank', 'AP'].includes(menu.title)) return null
            if (!permissions.includes(menu.code)) return null
            
          }
          return (
            <Fragment key={i}>
              <Title>{menu.title}</Title>
              <ul>
                {menu.items.map((l0, a) => {
                  return (
                    <li key={a} className="l0">
                      <Item {...l0} />
                      <ul>
                        {l0.items.map((l1, b) => {
                          if (l1.code && permissions != undefined) {
                            if (!permissions.includes(l1.code)) return null
                          }
                          return (
                            <li key={b} className="l1">
                              <Item {...l1} />
                              <ul>
                                {l1.items.map((l2, c) => {
                                  if (l2.code && permissions != undefined) {
                                    if (!permissions.includes(l2.code)) return null
                                  }
                                  return (
                                    <li key={c} className="l2">
                                      <Item {...l2} />
                                      <ul>
                                        {l2.items.map((l3, d) => {
                                          if (l3.code && permissions != undefined) {
                                            if (!permissions.includes(l3.code)) return null
                                          }
                                          return (
                                            <li key={d} className="l3">
                                              <Item {...l3} />
                                              <ul>
                                                {l3.items.map((l4, e) => {
                                                  if (l4.code && permissions != undefined) {
                                                    if (!permissions.includes(l4.code)) return null
                                                  }
                                                  return (
                                                    <li key={e} className="l4">
                                                      <Item {...l4} />
                                                    </li>
                                                  )
                                                })}
                                              </ul>
                                            </li>
                                          )
                                        })}
                                      </ul>
                                    </li>
                                  )
                                })}
                              </ul>
                            </li>
                          )
                        })}
                      </ul>
                    </li>
                  )
                })}
              </ul>
            </Fragment>
          )
        })}
      </div>
    </div>
  );
};

export default LeftSidebar;
